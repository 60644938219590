import { atom } from 'recoil'

export const poolUsersCountState = atom({
    key: 'poolUsersCountState',
    default: '--',
  });


export const poolTotalRewardClaimedState = atom({
    key: 'poolTotalRewardClaimedState',
    default: '--',
  });
  
export const poolTotalWithdrawState = atom({
    key: 'poolTotalWithdrawState',
    default: '--',
  });


  export const poolTotalDepositedState = atom({
    key: 'poolTotalDepositedState',
    default: '--',
  });
 