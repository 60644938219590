import ApolloClient, { gql } from "apollo-boost";

export const StakingClientSubgraph = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/g-nidhal/swu-stacking-contract'
});


export const getPoolInfo = gql`
query getPoolInfo($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!){
    poolInfos(first: 1) {
        userCount
        totalTransactionCount
        withdrawTransactionCount
        claimTransactionCount
        depositTransactionCount
        totalRewardClaimed
        totalWithdraw
        totalDeposited
    }

}`
