
import { React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { poolTotalDepositedState } from '../../atoms/atomsStackingInfo';
import { getPoolInfo, StakingClientSubgraph } from '../../services/graphqlStackingInfo';

export default function PoolTotalDepoited() {
    const { t, i18n } = useTranslation();
    const [poolTotalDepoited , setPoolTotalDepoited] = useRecoilState(poolTotalDepositedState)

    useEffect(()=>{

        // setPoolUsersCount(22);
        remoteData()

    },[])

    const remoteData = (query) => {
        return StakingClientSubgraph.query({
            query: getPoolInfo,
            variables: {
                offset: 0,
                limit: 0,
                orderBy: '',
                orderDirection: 'desc'
            }
             
        }).then((res) => {
            setPoolTotalDepoited(res.data.poolInfos[0].totalDeposited)
            console.log('Nidhal',res.data.poolInfos[0].totalDeposited )
        })
    }

    return (

        <div>
        
            <p className="ts-label">
               {t('Total Deposited')}
               <span className="ts-value"> {poolTotalDepoited == '--' ? '--' : parseInt(poolTotalDepoited)} </span>
            

            </p>
            </div>
    )
}