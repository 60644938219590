
import { React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { poolUsersCountState } from '../../atoms/atomsStackingInfo';
import { getPoolInfo, StakingClientSubgraph } from '../../services/graphqlStackingInfo';

export default function PoolUsersCount() {
    const { t, i18n } = useTranslation();
    const [poolUsersCount , setPoolUsersCount] = useRecoilState(poolUsersCountState)

    useEffect(()=>{

        // setPoolUsersCount(22);
        remoteData()

    },[])

    const remoteData = (query) => {
        return StakingClientSubgraph.query({
            query: getPoolInfo,
            variables: {
                offset: 0,
                limit: 0,
                orderBy: '',
                orderDirection: 'desc'
            }
             
        }).then((res) => {
            setPoolUsersCount(res.data.poolInfos[0].userCount)
            console.log('Nidhal',res.data.poolInfos[0].userCount )
        })
    }

    return (

        <div>
        
            <p className="ts-label">
               {t('Holders')}
               <span className="ts-value"> {poolUsersCount == '--' ? '--' : parseInt(poolUsersCount)} </span>
            

            </p>
            </div>
    )
}