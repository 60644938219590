import React, { useEffect, useState } from "react";
import Logo from "../assets/logo.png";
import LogoM from "../assets/logo.png";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import LogoutIcon from "@mui/icons-material/Logout";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { ReactComponent as MetamaskLogo } from "../assets/metamask.svg";
import { AppNetworkId, BLOCK_EXPRORER_URL, ChainProps } from "../ABI/AppParams";
import { Link } from 'react-router-dom'

import Web3Modal from "web3modal";
import { useRecoilState } from 'recoil'

import Web3 from "web3";
import { accountState, isOwnerState, networkIdState } from "../atoms/atoms";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
import ConnectMetamaskAlert from "./ConnectMetamaskAlert";
import AlertModal from "./AlertModal";
import { poolStackingInfoActive } from './../ABI/AppParams';


function Navbar() {

  const { t, i18n } = useTranslation();
  
  const [account, setAccount] = useRecoilState(accountState)
  const [networkId, setNetworkId] = useRecoilState(networkIdState)
  const [isOwner, setIsOwner] = useRecoilState(isOwnerState)

  const [popoverEl, setPopoverEl] = React.useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open2 = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [web3, setWeb3] = useState(null);
  const [web3Modal, setWeb3Modal] = useState(null);
  const [provider, setProvider] = useState(null);
  const [connectMetamaskAlertOpened, setConnectMetamaskAlertOpened] = useState(false);
  const [installMetamaskAlert, setInstallMetamaskAlert] = useState(false);

  useEffect(() => {
    const logout = window.localStorage.getItem("logout");
    if (logout !== undefined && logout !== null && logout !== "true") {
      callWeb3();
      //loadData();
    }else{
      setConnectMetamaskAlertOpened(true)
    }
    if (localStorage.getItem("auth")) {
      //callWeb3().finally(() => setReady(true));
    } else {
      //setReady(true);
    }
  }, []);

  const callWeb3 = async () => {

    if (!window.ethereum) {
     
      setInstallMetamaskAlert(true);
      return;
     
    }

    const providerOptions = {

    };

    const web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions, // required
    });

    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    setWeb3Modal(web3Modal);
    setProvider(provider);
    setWeb3(web3);

    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    setAccount(accounts[0]);


    const selectedNetworkId = await web3.eth.net.getId();
    console.log(selectedNetworkId, "network");
    setNetworkId(selectedNetworkId)

    subscribeProvider(provider);
    window.localStorage.setItem("logout", "false");

  };

  const subscribeProvider = (provider) => {
    if (!provider || !provider.on || provider.binded) {
      return;
    }
    provider.binded = true;

    //provider.on("close", () => this.resetApp());
    provider.on("accountsChanged", async (accounts) => {
      console.log("accountsChanged");
      //if (!this._ismounted) return;
      setAccount(accounts[0])
    });
    provider.on("chainChanged", async (chainId) => {
      //if (!this._ismounted) return;
      //const { web3 } = this.state;
      //const networkId = await this.open2web3?.web3.eth.net.getId();
      //setAccount(accounts[0]);

    });

    provider.on("networkChanged", async (selectedNetworkId) => {
      //if (!this._ismounted) return;
      //const { web3 } = this.state;
      //const chainId = await this.open2web3?.web3.eth.chainId();
      //console.log("networkId", networkId);
      //console.log("chainId", chainId);
      //this.setState({ chainId, networkId }, this.getAccountAssets)
      setNetworkId(selectedNetworkId)
    });
  };


  const resetApp = async () => {
    web3Modal.clearCachedProvider();
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    window.localStorage.setItem("logout", "true");
    setAccount(null)

  };

  //todo
  const readLPcake = async () => {
    /*try {
      dispatch({ type: SET_BALANCE, payload: "--" });
      const web3 = new Web3(Web3.givenProvider);
      let contract = new web3.eth.Contract(LPcakeAbi, STAKED_TOKEN_CONTRACT_ADDRESS);
      let balanceAddress = account
      dispatch({ type: REFRESH_BALANCE, payload: { web3, contract, account: balanceAddress } })
    } catch (err) {
      console.error(err);
    }*/
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose2 = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPopover = (event) => {
    setPopoverEl(event.currentTarget);
    copyToClipboard();
  };

  const handleClosePopover = () => {
    setPopoverEl(null);
  };

  const openPopover = Boolean(popoverEl);
  const id = openPopover ? "simple-popover" : undefined;

  const copyToClipboard = async (e) => {
    await navigator.clipboard.writeText(account);
  };

  const deconnect = async () => {
    //localStorage.removeItem("auth");
    resetApp();
    window.location.reload();
  };

  const handleSwitchNetwork = () => {

    if (networkId * 1 !== AppNetworkId) {
      window.ethereum
        .request({
          //method: 'wallet_addEthereumChain',
          method: 'wallet_switchEthereumChain',
          params: [ChainProps],
        })
        .then((result) => {
          console.log("wallet_addEthereumChain", result);
          readLPcake();
        })
        .catch((error) => {
          if (error.code === 4001) {
            console.log("We can't encrypt anything without the key.", error);
          } else {
            console.error(error);
          }
        });
    }

  }

  return (
    <div className="container-fluid">
    
      <nav className="nav">
        <a href="https://swu-coin.com/">
          <img
            className="logo-desktop"
            src={Logo}
            alt="swu logo"
            width="120"
          />
          <img
            className="logo-mobile"
            src={LogoM}
            alt="swu logo"
            width="80"
          />
        </a>
       {poolStackingInfoActive ? null :  <div style={{display:'flex',alignItems:'center'}} className="minN">
          <React.Fragment>
            {networkId !=='' && networkId !== null && networkId != AppNetworkId ?
              <button className="neter dN-mobile styI" onClick={handleSwitchNetwork}>
                {t('Invalid network, switch to BSC')}
              </button>
              : null}
              <div> <LanguageSelector></LanguageSelector></div>
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
              className="drop-container"
              id="header-menu"
              >
              <Button variant="outlined" size="large" onClick={callWeb3}>
                {account
                  ? `${account.slice(0, 6)}...${account.slice(
                    account.length - 4,
                    account.length
                  )}`
                  : t("Connect Metamask")}
              </Button>
              <Button
                disabled={account == null || account == ''}
                size="small"
                className="user-btn"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
           
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              id="poper-container"
              style={{zIndex:'1'}}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose2}>
                      <MenuList>
                        <div
                          className="poper-header"
                          style={{
                            margin: "0",
                            display: "flex",
                            alignItems: "center",
                            width: "280px",
                            justifyContent: "space-between",
                            padding: "3px 1em",
                          }}
                        >
                          <h4>{t('Account')}</h4>
                        </div>

                        <div className="poper-body">
                          <MetamaskLogo />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <a href={`${BLOCK_EXPRORER_URL}/address/${account}`} target="_blank"

                            >
                              {`${account.slice(0, 6)}...${account.slice(
                                account.length - 4,
                                account.length
                              )}`}
                            </a>
                            <p className="ts-label">Metamask</p>
                          </div>
                          <ContentCopyIcon
                            aria-describedby={id}
                            className="poper-icon"
                            onClick={handleClickPopover}
                          />
                          <Popover
                            id={id}
                            open={openPopover}
                            anchorEl={popoverEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Typography sx={{ p: 2 }}>Copied!</Typography>
                          </Popover>
                        </div>

                        {isOwner ?

                          <div
                            className="poper-header"
                            style={{
                              margin: "0",
                              display: "flex",
                              alignItems: "center",
                              width: "280px",
                              justifyContent: "space-between",
                              padding: "3px 1em",
                            }}
                          >
                            <Link to={`/admin-dashboard/`}><h4>Admin dashboard</h4></Link>

                          </div>

                          : null}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
          {/* <Button
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick}
            variant="outlined"
            size="large"
          >
            <MoreHorizIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open2}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
          <MenuItem onClick={handleClose}>
              <a href="https://swu-coin.com/" className="documentation" target="_blank">
                {t('Documentation')}
              </a>
            </MenuItem> 
          </Menu> */} 
        </div> }
      </nav>
      <div style={{textAlign:'center'}} className="dN-desktop">
      {networkId !== null && networkId != AppNetworkId ?
              <button className="neter styI" onClick={handleSwitchNetwork}>
                Invalid network switch to BSC
              </button>
              : null}
      </div>


      <AlertModal className="modalMeta" message={t('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html')} open={installMetamaskAlert} onClose={(e)=>setInstallMetamaskAlert(false)}></AlertModal>
      {/*
      <ConnectMetamaskAlert open={connectMetamaskAlertOpened} onClose={(e)=>setConnectMetamaskAlertOpened(false)}></ConnectMetamaskAlert>
    */}
    </div>
  );
}


export default Navbar;
