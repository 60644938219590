export const COMINIG_SOON_ACTIVATED = false;

export const TOKEN_NAME = "O2B"

export const BASE_REWARD_PER_BLOCK = 0.03;

export const MORALIS_API_Key = "NFy1tc7ZUdyvv8zwrIFtTifWkvTFcFk2S36E8YbIpw6jajs6hS2ajblk94Ad4Ahj"
export const MORALIS_URL = "https://deep-index.moralis.io/api/v2/erc20/"
export const MORALIS_CHAIN = "bsc"

export const poolStackingInfoActive = false;

//------------------------testnet bsc------------------------------------------------------------------

export const REWARD_TOKEN_CONTRACT_ADDRESS = "0xd66b8E3685fBc8f98EdEC0810dD27Faeb51bb0F2";
export const STAKED_TOKEN_CONTRACT_ADDRESS = "0xd66b8E3685fBc8f98EdEC0810dD27Faeb51bb0F2";
export const STAKING_CONTRACT_ADDRESS = "0xDcF347b45815F40074b9744DbBd9283356ebB35D";

export const REWARD_TOKEN_CONTRACT_ADDRESS_MORALIS = "0x58f876857a02d6762e0101bb5c46a8c1ed44dc16";
export const STAKED_TOKEN_CONTRACT_ADDRESS_MORALIS = "0x58f876857a02d6762e0101bb5c46a8c1ed44dc16";

export const RPC_URL = "https://goerli.infura.io/v3/ec7af41df5d14bd7ae5ff20245d25d9d";
export const RPC_URL_WS = "wss://goerli.infura.io/ws/v3/ec7af41df5d14bd7ae5ff20245d25d9d";



export const BLOCK_EXPRORER_URL = "https://goerli.etherscan.io/"


export const AppNetworkId = 5; //56 mainnet
/*export const ChainProps = {
    chainId: "0x61", //open2web3.web3.utils.toHex(97)
    chainName: 'BSC Testnet',
    nativeCurrency: {
        name: 'BSC Testnet',
        symbol: 'BNB',
        decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-2-s2.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com']
};*/
export const ChainProps = {
  chainId: '0x5' 
};

/*Token contract:  0xd66b8E3685fBc8f98EdEC0810dD27Faeb51bb0F2
staking contract:  0x9434E79A61d3512CA828DC031bec415D060092Be
staking proxy:  0xDcF347b45815F40074b9744DbBd9283356ebB35D
owner:  0xE6585C6103f0972F408529b9C1452Ac0751b5699*/

//----------------------------fin testnet bsc------------------------------
//------------------------Mainnet bsc------------------------------------------------------------------
/*
export const REWARD_TOKEN_CONTRACT_ADDRESS = "0x958Cc5ac2eFa569CD9ad9B9B88245E1F038b02bE";
export const STAKED_TOKEN_CONTRACT_ADDRESS = "0x958Cc5ac2eFa569CD9ad9B9B88245E1F038b02bE";
export const STAKING_CONTRACT_ADDRESS = "0x2FcC2d210C2c7C8aE341208cECd9e58b5a077bE4";

export const REWARD_TOKEN_CONTRACT_ADDRESS_MORALIS = "0x58f876857a02d6762e0101bb5c46a8c1ed44dc16";
export const STAKED_TOKEN_CONTRACT_ADDRESS_MORALIS = "0x58f876857a02d6762e0101bb5c46a8c1ed44dc16";

export const RPC_URL = "https://bsc-dataseed.binance.org/";
export const RPC_URL_WS = "wss://speedy-nodes-nyc.moralis.io/f21e97fc3106a0a9594f5ff3/bsc/testnet/ws";



export const BLOCK_EXPRORER_URL = "https://bscscan.com"


export const AppNetworkId = 56;
export const ChainProps ={
  chainId: "0x38", //open2web3.web3.utils.toHex(56)
  chainName: 'BSC',
  nativeCurrency: {
      name: 'BSC',
      symbol: 'BNB',
      decimals: 18,
  },
  rpcUrls: ['https://bsc-dataseed.binance.org/'],
  blockExplorerUrls: ['https://bscscan.com']
};
*/

/*impl 0x5cb1576eD35979c00B0106D5c3c9b914F41547cd
token proxy 0x958Cc5ac2eFa569CD9ad9B9B88245E1F038b02bE
staking contract:  0x78e1987e3a40149c1636444ebfEF3360261B9FFb
staking proxy:  0x2FcC2d210C2c7C8aE341208cECd9e58b5a077bE4
*/


//----------------------------fin Mainnet bsc------------------------------

//------------------------fuji bsc------------------------------------------------------------------
/*
export const REWARD_TOKEN_CONTRACT_ADDRESS = "0xF03fd7B88a82bCa653b07f58894fB114e16b4cf1";
export const STAKED_TOKEN_CONTRACT_ADDRESS = "0xF03fd7B88a82bCa653b07f58894fB114e16b4cf1";
export const STAKING_CONTRACT_ADDRESS = "0x82955152EAddC3D0F078C298C94f64120588339b";

export const REWARD_TOKEN_CONTRACT_ADDRESS_MORALIS = "0x58f876857a02d6762e0101bb5c46a8c1ed44dc16";
export const STAKED_TOKEN_CONTRACT_ADDRESS_MORALIS = "0x58f876857a02d6762e0101bb5c46a8c1ed44dc16";

export const RPC_URL = "https://api.avax-test.network/ext/bc/C/rpc";
export const RPC_URL_WS = "wss://speedy-nodes-nyc.moralis.io/f21e97fc3106a0a9594f5ff3/bsc/testnet/ws";



export const BLOCK_EXPRORER_URL = "https://testnet.snowtrace.io/"


export const AppNetworkId = `0x${Number(43113).toString(16)}`; //56 mainnet
export const ChainProps = {
    chainId: `0x${Number(43113).toString(16)}`,
    chainName: 'Fuji Avalanche',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://testnet.snowtrace.io/']
};

*/
/*Token contract:  0x99D6565FDe52D984A697e2e45F164AF328EF2BA3
Token contract proxy:  0xF03fd7B88a82bCa653b07f58894fB114e16b4cf1
staking contract:  0xd789bf5b72811Fa82B8CFF35F3E38fB781d29961
staking proxy:  0x82955152EAddC3D0F078C298C94f64120588339b
owner:  0xE6585C6103f0972F408529b9C1452Ac0751b5699*/


//----------------------------fin fuji bsc------------------------------





