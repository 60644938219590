import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import { COMINIG_SOON_ACTIVATED, STAKED_TOKEN_CONTRACT_ADDRESS, STAKING_CONTRACT_ADDRESS, TOKEN_NAME } from "../ABI/AppParams";
import Web3 from "web3";
import { LPcakeAbi } from "../ABI/LPcakeAbi";
import { StackingAbi } from "../ABI/StackingAbi";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useRecoilState } from 'recoil'
import { accountState, balanceVersionState, networkIdState, poolTotalStakedState, rewardTokenPerBlockState, walletBalanceState } from "../atoms/atoms";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import RangeSlider from 'react-bootstrap-range-slider'
import {
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Form,
  Button
} from 'react-bootstrap'
import { getApyPerPeriod, getWithdrawFeesPerPeriod } from "./AppServices";
import DepositDisclaimer from "./DepositDisclaimer";
import { useTranslation } from "react-i18next";
const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.95);
  -webkit-tap-highlight-color: transparent;
`;

function ValidationModal() {

  const { t, i18n } = useTranslation();

  const [depositInput, setDepositInput] = useState(0);
  const [open, setOpen] = useState(false);
  const [stakingPeriod, setStakingPeriod] = React.useState(12);
  const handleClose = () => setOpen(false);
  const [isApprouveLoading, setIsApprouveLoading] = useState(false);
  const [isDepositLoading, setIsDepositLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [checkAllowance, setCheckAllowance] = useState(true);

  const [balance, setBalance] = useRecoilState(walletBalanceState)
  const [account, setAccount] = useRecoilState(accountState)
  const [balanceVersion, setBalanceVersion] = useRecoilState(balanceVersionState)
  const [networkId, setNetworkId] = useRecoilState(networkIdState)
  const [poolTotalStaked, setPoolTotalStaked] = useRecoilState(poolTotalStakedState)
  const [stakingRewardRate, setStakingRewardRate] = useRecoilState(rewardTokenPerBlockState);
  const [estimatedReward, setEstimatedReward] = useState(0)
  const [estimatedApy, setEstimatedApy] = useState(0)

  const [openDisclaimer, setOpenDisclaimer] = useState(false);



  useEffect(() => {
    if (parseFloat(depositInput) > 0 && parseFloat(stakingPeriod) >= 4) {
      let newPoolTotal = parseFloat(poolTotalStaked) + parseFloat(depositInput);
      if (newPoolTotal > 0 && parseFloat(stakingRewardRate) > 0) {
        /*let poolParticipation = (parseFloat(depositInput) / newPoolTotal)
        //blocks in a day => 28800
        let totalRewardPerMonth = parseFloat(stakingRewardRate) * 28800 * 30;
        let withdrawFees = parseFloat(depositInput) * getWithdrawFeesPerPeriod(parseFloat(stakingPeriod));
        let finalStakingReward = (totalRewardPerMonth * poolParticipation * parseFloat(stakingPeriod)) - withdrawFees;
        setEstimatedReward(finalStakingReward);
        setEstimatedApy(finalStakingReward / parseFloat(depositInput) * 100)*/

        let rate = getApyPerPeriod(parseInt(stakingPeriod));
        setEstimatedApy(rate)
        setEstimatedReward(Math.round((depositInput * rate )/100))
      }
    } else {
      setEstimatedReward(0);
      setEstimatedApy(0)
    }
    return () => {

    }
  }, [poolTotalStaked, depositInput, stakingPeriod, stakingRewardRate])




  const handleOpen = () => {

    const web3 = new Web3(window.ethereum);

    setCheckAllowance(true);
    const contractEpToken = new web3.eth.Contract(LPcakeAbi, STAKED_TOKEN_CONTRACT_ADDRESS);
    contractEpToken.methods
      .allowance(account, STAKING_CONTRACT_ADDRESS)
      .call().then(result => {
        result = web3.utils.fromWei(result, "ether");
        //console.log("test", result, depositInput)
        if (result == 0) {
          setCurrentStep(0);
        } else {
          setCurrentStep(1);
        }
        setCheckAllowance(false);
      });

    setIsApprouveLoading(false);
    setIsDepositLoading(false);
    setDepositInput(0);
    setOpen(true);
  }

  useEffect(() => {
    setInputDefaultValue();
    /*setTimeout(() => {
      setInputDefaultValue();
  }, 1000);*/
    return () => {

    }
  }, [balance])
  useEffect(() => {
    verifieStep();
    return () => {

    }
  }, [depositInput])
  const setInputDefaultValue = (e) => {
    try {
      if (balance == "--" || balance == 0) {
        setDepositInput(50000);
      } else {
        setDepositInput(Math.round(parseFloat(balance.replace(",", ".")) / 2),2);
      }
    } catch (e) {
      console.error(e)
      setDepositInput(0)
    }
  };
  const handleMax = (e) => {
    try {
      if (balance == "--" || balance == 0) {
        setDepositInput(50000);
      } else {
        setDepositInput(`${balance.replace(",", ".") || '0'}`);
      }
    } catch (e) {
      console.error(e)
      setDepositInput(0)
    }
  };

  const onChange = (event) => {
    if (event.target.value < 0) {
      setDepositInput(0)
    } else {
      setDepositInput(event.target.value);
    }
  };



  const verifieStep = () => {
    setCurrentStep(1);
    if (depositInput == "" || account == '' || account == null) {
      return;
    }
    try {
      if(!window.ethereum) return;
      const web3 = new Web3(window.ethereum);
      const contractEpToken = new web3.eth.Contract(LPcakeAbi, STAKED_TOKEN_CONTRACT_ADDRESS);
      setCheckAllowance(true);
      contractEpToken.methods
        .allowance(account, STAKING_CONTRACT_ADDRESS)
        .call().then(result => {
          //console.log("test", result, depositInput)
          result = web3.utils.fromWei(result, "ether");

          if (parseFloat(result) >= parseFloat(depositInput)) {
            setCurrentStep(1);
          } else {
            setCurrentStep(0);
          }
          setCheckAllowance(false);
        });
    } catch (err) {
      console.error(err);
    }
  }

  const acceptDisclaimer = () => {
    setOpenDisclaimer(false)
    if(currentStep == 0){
      handleApprouve()
    }else if(currentStep == 1){
      handleDeposit();
    }
  }

  async function handleApprouve() {
    setIsApprouveLoading(true);
    try {
      const web3 = new Web3(window.ethereum);
      
      let amount = web3.utils.toWei(depositInput+"", "ether");
      
      const contractEpToken = new web3.eth.Contract(LPcakeAbi, STAKED_TOKEN_CONTRACT_ADDRESS);
      await contractEpToken.methods
        .approve(STAKING_CONTRACT_ADDRESS, amount)
        .send({ from: account });
      handleDeposit();
    } catch (err) {
      console.error(err);
    }
    setIsApprouveLoading(false);
    verifieStep();
  }

  async function handleDeposit() {
    setIsDepositLoading(true);
    try {
      const web3 = new Web3(window.ethereum);
      let amount = web3.utils.toWei(depositInput+"", "ether");
      const contract = new web3.eth.Contract(
        StackingAbi,
        STAKING_CONTRACT_ADDRESS
      );
      const depositResult = await contract.methods
        .deposit(account, amount)
        .send({ from: account });
      setBalanceVersion(balanceVersion + 1);
      console.log(depositResult);
    } catch (err) {
      console.error(err);
    }
    setIsDepositLoading(false);
    handleClose();
  }
  const steps = [
    'Approuve',
    'Deposit',
  ];
  return (
    <div className="centerBlo">

  
        <h4 className="centerTex">{t('Add Liquidity')}</h4>
       
       
            <div className="modalInp">
           
              <div>
                <label>{t('Choose the amount you want to stake')}</label>
                <Form.Group className="posiCoin">

                  <div>

                    <RangeSlider
                      value={depositInput}
                      variant="light"
                      tooltip="off"
                      size="sm"
                      onChange={e => setDepositInput(e.target.value)}
                      className="RangeS"
                      max={balance>0? balance:100000}
                      step='.01'

                    />

                  </div>
                  <div>
                    <Input value={depositInput} onChange={e => setDepositInput(e.target.value)} size='sm' />
                  </div>
                  <div>
                    <label className="modal-coin small" style={{marginBottom:0}}>{TOKEN_NAME}</label>
                  </div>
                </Form.Group>
              </div>
              <div style={{ paddingTop: 24 }}>
                <label>{t('Estimated Duration (Months)')}</label>
                <Form.Group className="posiCoin">

                  <div>

                    <RangeSlider
                      value={stakingPeriod}
                      variant="light"
                      tooltip="off"
                      size="sm"
                      onChange={e => setStakingPeriod(e.target.value)}
                      className="RangeS"
                      max={14}
                      min={4}

                    />

                  </div>
           

                  <div>
                    <Input value={stakingPeriod} onChange={e => setStakingPeriod(e.target.value)} className="labelI" size='sm' />
                  </div>
                  <div>
          <label className="small">{t('Months')}</label>
        </div>
                 
                </Form.Group>
                <div style={{paddingTop:45,paddingBottom:20}} className="AligInfo">
                  <div className="AlignEs">
                <label className="inputEX" style={{ whiteSpace:'nowrap',flex:1 }}>{t('Estimated APY')}</label>
                <Input value={estimatedApy} onChange={e => setEstimatedApy.toLocaleString().replace(",",".")(e.target.value)} size='sm'   style={{flex:1 }}/> %
                </div>
                <div className="AlignEs">
                <label className="inputEX" style={{whiteSpace:'nowrap',flex:1 }}>{t('O2B earned')}</label>
                <Input value={estimatedReward} onChange={e => setEstimatedReward.toLocaleString().replace(",",".")(e.target.value)} size='sm' className="inputEX" style={{flex:1 }}/> <span className="modal-coin small" style={{marginBottom:0}}>{TOKEN_NAME}</span>
                </div>
                   
                  </div>
              </div>

              {parseFloat(depositInput) > parseFloat(balance.replace(",", ".")) ? (
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="error">
                  {t('This amount is not available on your wallet!')}
                </Alert>
              </Stack>
            ) : null}


            <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
              {
                currentStep == 0 ?
                  !isApprouveLoading ? (
                    <button
                      className="btn-form btnd"
                      disabled={COMINIG_SOON_ACTIVATED == false && ( checkAllowance == true || currentStep == 1 || parseFloat(depositInput) <= 0 || depositInput == "" || parseFloat(depositInput) > parseFloat(balance))}
                      onClick={(e) => setOpenDisclaimer(true)}
                    >
                      {t('Approve')}
                    </button>
                  ) : (

                    <button className="btn-form">
                      <CircularProgress />
                    </button>
                  ) : null
              }
              {
                currentStep == 1 ?
                  !isDepositLoading ? (
                    <button
                      className="btn-form btnd"
                      disabled={COMINIG_SOON_ACTIVATED == false && (checkAllowance == true || currentStep == 0 || parseFloat(depositInput) <= 0 || depositInput == "" || parseFloat(depositInput) > parseFloat(balance))}
                      onClick={(e) => setOpenDisclaimer(true)}
                    >
                      {t('Deposit')}
                    </button>
                  ) : (

                    <button className="btn-form">
                      <CircularProgress className="circleS" />
                    </button>
                  ) : null
              }

            </div>

             
            </div>
            
      
     

      <DepositDisclaimer open={openDisclaimer} onClose={(e)=>setOpenDisclaimer(false)} acceptDisclaimer={acceptDisclaimer}></DepositDisclaimer>

    </div>
  );

}

export default ValidationModal;
