import React, { useEffect, useState } from 'react'

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";


import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { poolTotalStakedState, rewardTokenPerBlockState } from '../../atoms/atoms';


export default function PoolApy() {

    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [poolTotalStaked, setPoolTotalStaked] = useRecoilState(poolTotalStakedState)
    const [stakingRewardRate, setStakingRewardRate] = useRecoilState(rewardTokenPerBlockState);
    const [apyActuel,setApyActuel] = useState('--');


    useEffect(() => {
      calculerAPY();
    }, [poolTotalStaked,stakingRewardRate])
    

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const calculerAPY = () => {
        if(stakingRewardRate!=='--' && poolTotalStaked!=='--'){
            const blockPerYear = 10512000;
            const totalDistributionPerYear = blockPerYear * stakingRewardRate;
            var apy;
           

            apy = (totalDistributionPerYear / poolTotalStaked) * 100;
            
            setApyActuel(apy);
        }
    }

    const open = Boolean(anchorEl);
    

    return (
        <div>
            <p className="ts-label">
            {t('Current APY pool')} <span className="ts-value">{apyActuel == '--'?apyActuel:parseFloat(apyActuel).toFixed(2)} %</span>
                <Typography
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    style={{ display: "inline-flex" }}
                >
                    <InfoIcon style={{ paddingLeft: "0.2em" }} />
                </Typography>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography className="typoinfo" sx={{ p: 1 }}>{t('APY annually farm.')}</Typography>
                </Popover>
            </p>
            
            {/*<p className="ts-value">{store.apy == '--' ? '--' : parseFloat(store.apy).toLocaleString()} %</p>*/}

        </div>
    )
}
