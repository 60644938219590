import PoolTotalStaked from './../components/PoolTotalStaked';
import Logo from "../assets/logo.png"
import FarminigMultiplier from './../components/FarminigMultiplier';
import Apy from './../components/Apy';
import PoolUsersCount from '../components/stakingInfo/poolUsersCount';
import PoolTotalRewardClaimed from '../components/stakingInfo/poolTotalRewardClaimed';
import PoolTotalWithdraw from '../components/stakingInfo/poolTotalWithdraw';
import PoolTotalDepoited from '../components/stakingInfo/poolTotalDeposited';
import PoolApy from './../components/stakingInfo/poolApy';



export default function StackingInfo(){

    return (


        <div className="card">
        <div>  
        <div className="card-title">
          <img
              
              src={Logo}
              alt="swu logo"
              width="120"
              
           
            />
            <h1>Staking Statistic</h1>
            </div>
            </div> 
      
  
  
        <div>
        <div className="alignDiv">
          <div className="div1 centerBlo">
          <PoolTotalStaked></PoolTotalStaked>
          <FarminigMultiplier></FarminigMultiplier>
          <PoolApy></PoolApy>
          
          </div>

          
          </div>


          <div className="alignDiv">
          <div className="div1 centerBlo">
          <PoolTotalDepoited></PoolTotalDepoited>
          <PoolTotalRewardClaimed></PoolTotalRewardClaimed>
          <PoolTotalWithdraw></PoolTotalWithdraw>
          {/* <Apy></Apy> */}
          
          </div>

          
          </div>


          <div className="alignDiv">
          <div className="div1 centerBlo">
          <PoolUsersCount></PoolUsersCount>
          {/* <FarminigMultiplier></FarminigMultiplier>
          <Apy></Apy> */}
          
          </div>

          
          </div>
         
         
         
       
         
          
        
  
          </div>
  
   
  
        </div>
    )
}