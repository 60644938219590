
import { React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {  poolTotalWithdrawState } from '../../atoms/atomsStackingInfo';
import { getPoolInfo, StakingClientSubgraph } from '../../services/graphqlStackingInfo';

export default function PoolTotalWithdraw() {
    const { t, i18n } = useTranslation();
    const [poolTotalWithdraw , setPoolTotalWithdraw] = useRecoilState(poolTotalWithdrawState)

    useEffect(()=>{

        // setPoolUsersCount(22);
        remoteData()

    },[])

    const remoteData = (query) => {
        return StakingClientSubgraph.query({
            query: getPoolInfo,
            variables: {
                offset: 0,
                limit: 0,
                orderBy: '',
                orderDirection: 'desc'
            }
            
        }).then((res) => {
            setPoolTotalWithdraw(res.data.poolInfos[0].totalWithdraw)
            console.log('Nidhal',res.data.poolInfos[0].totalWithdraw )
        })
    }

    return (

        <div>
        
            <p className="ts-label">
               {t('Total Withdraw')}
               <span className="ts-value"> {poolTotalWithdraw == '--' ? '--' : parseFloat(poolTotalWithdraw).toFixed(2)} </span>
            

            </p>
            </div>
    )
}