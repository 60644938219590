
import { React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { poolTotalRewardClaimedState } from '../../atoms/atomsStackingInfo';
import { getPoolInfo, StakingClientSubgraph } from '../../services/graphqlStackingInfo';

export default function PoolTotalRewardClaimed() {
    const { t, i18n } = useTranslation();
    const [poolTotalRewardClaimed , setPoolTotalRewardClaimed] = useRecoilState(poolTotalRewardClaimedState)

    useEffect(()=>{

        // setPoolUsersCount(22);
        remoteData()

    },[])

    const remoteData = (query) => {
        return StakingClientSubgraph.query({
            query: getPoolInfo,
            variables: {
                offset: 0,
                limit: 0,
                orderBy: '',
                orderDirection: 'desc'
            }
             
        }).then((res) => {
            setPoolTotalRewardClaimed(res.data.poolInfos[0].totalRewardClaimed)
            console.log('Nidhal',res.data.poolInfos[0].totalRewardClaimed )
        })
    }

    return (

        <div>
        
            <p className="ts-label">
               {t('Total Reward Claimed')}
               <span className="ts-value"> {poolTotalRewardClaimed == '--' ? '--' : parseFloat(poolTotalRewardClaimed).toFixed(2)} </span>
            

            </p>
            </div>
    )
}